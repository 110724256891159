:root {
    --an-app-header-height: 65px;
    --an-app-header-backgrou-color: #343a40;
    --primary: #f76262 !important;
    --backgrond-color: #216583;
    --info: #f76262 !important;
}
@media (min-width: 0px) and (max-width: 767.98px) {
    :root {
        --an-app-header-height: 53px;
    }
}
@media (min-width: 1200px) {
    .container {
        max-width: 1200px !important;
    }
}
* {
    font-family: "Muli", sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Muli", sans-serif;
    font-weight: bold;
}
/* primary button style */
.btn-primary {
    color: #fff;
    background-color: var(--primary) !important;
    border-color: transparent !important;
}

.btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
}

.btn-primary:focus,
.btn-primary.focus {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.an-text-info {
    color: var(--info) !important;
}
.an-bg-info {
    background-color: var(--info) !important;
}

/* Material style form (signup, login, covid, verification, fp forms) */

.form-label-group {
    position: relative;
    margin-bottom: 1rem;
  }

  .form-label-group > input,
  .form-label-group > label {
    height: 3.125rem;
    padding: 0.75rem;
  }

  .form-label-group > label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    margin-bottom: 0; /* Override default `<label>` margin */
    line-height: 1.5;
    color: #495057;
    pointer-events: none;
    cursor: text; /* Match the input under the label */
    border: 1px solid transparent;
    border-radius: 0.25rem;
    transition: all 0.1s ease-in-out;
  }

  .form-label-group input::-webkit-input-placeholder {
    color: transparent;
  }

  .form-label-group input:-ms-input-placeholder {
    color: transparent;
  }

  .form-label-group input::-ms-input-placeholder {
    color: transparent;
  }

  .form-label-group input::-moz-placeholder {
    color: transparent;
  }

  .form-label-group input::placeholder {
    color: transparent;
    opacity: 0;
  }

  .form-label-group input:not(:placeholder-shown) {
    padding-top: 1.25rem;
    padding-bottom: 0.25rem;
  }

  .form-label-group input:not(:placeholder-shown) ~ label {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    font-size: 12px;
    color: #777;
  }

  small {
    color: #777;
    margin-left: 5px;
  }

  .skeleton-loading {
   background-size: 200% 100%;
   background-position: 100% 0;
   animation: skeleton-animate 1.4s infinite;
    background-image: linear-gradient(112deg, #efefef 20%, #e7e7e7 30%, #efefef 38%, #d6d6d6 40%, #efefef 48%)
  }

  @keyframes skeleton-animate {
   100% {
     background-position: -100% 0;
   }
  }

  /* Fallback for Edge
  -------------------------------------------------- */
  @supports (-ms-ime-align: auto) {
    .form-label-group > label {
      display: none;
    }
    .form-label-group input::-ms-input-placeholder {
      color: #777;
    }
  }

  /* Fallback for IE
  -------------------------------------------------- */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .form-label-group > label {
      display: none;
    }
    .form-label-group input:-ms-input-placeholder {
      color: #777;
    }
  }


